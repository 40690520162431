import { useState, useContext, useEffect } from 'react';
import { UserContext } from '../components/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;
const appleClientId = process.env.REACT_APP_WEB_APPLE_CLIENT_ID;
const appleRedirectURI = process.env.REACT_APP_WEB_APPLE_TEST_REDIRECT_URI;

const TestAppleSignin = () => {
  const [loading, setLoading] = useState(false);
  const [isAppleLoaded, setIsAppleLoaded] = useState(false);
  const { setUserId } = useContext(UserContext);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleAppleResponse = async (response) => {
    try {
      const { authorization: { code, id_token },user } = response;
console.log('response:: ', response);
         // Prepare the request payload
    const requestData = {
      code,
      id_token,
     
    };

    // Include name and email if they are available (only during the first login)
    if (user) {
      requestData.user = JSON.stringify(user); // Convert user object to a string to send to the backend
    }

      
      const res = await axios.post(`${apiUrl}/users/apple-web-login`, requestData);

      const { token, userId } = res.data;
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      setUserId(userId);
      navigate('/dashboard');
    } catch (err) {
      setError('Failed to authenticate with server');
      console.error('Server authentication error:', err);
    }
  };

  useEffect(() => {
    // Function to initialize Apple Sign In
    const initializeAppleSignIn = () => {
      try {
        if (window.AppleID) {
          window.AppleID.auth.init({
            clientId: appleClientId,
            scope: 'name email',
            redirectURI: appleRedirectURI,
            state: 'origin:web',
            usePopup: true
          });
          setIsAppleLoaded(true);
        }
      } catch (error) {
        console.error('Error initializing Apple Sign In:', error);
        setError('Failed to initialize Apple Sign In');
      }
    };

    // Check if AppleID is already available
    if (window.AppleID) {
      initializeAppleSignIn();
    } else {
      // If not, set up an event listener for when the script loads
      const scriptLoadCheck = setInterval(() => {
        if (window.AppleID) {
          initializeAppleSignIn();
          clearInterval(scriptLoadCheck);
        }
      }, 100);

      // Clear interval after 10 seconds if script hasn't loaded
      setTimeout(() => {
        clearInterval(scriptLoadCheck);
        if (!window.AppleID) {
          setError('Apple Sign In failed to load');
        }
      }, 10000);
    }

    // Cleanup
    return () => {
      console.log('cleanup');
    };
  }, []);

  const initiateAppleSignIn = async () => {
    if (!window.AppleID || !window.AppleID.auth) {
      setError('Apple Sign In is not initialized yet');
      return;
    }

    try {
      setLoading(true);
      const response = await window.AppleID.auth.signIn();
      await handleAppleResponse(response);
    } catch (error) {
      setError('Apple Sign In failed: ' + error.message);
      console.error('Apple Sign In Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button 
        onClick={initiateAppleSignIn}
        style={{
          padding: '10px 20px',
          backgroundColor: '#000',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '10px'
        }}
        disabled={loading || !isAppleLoaded}
      >
        {loading ? 'Loading...' : isAppleLoaded ? 'Sign in with Apple' : 'Initializing...'}
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default TestAppleSignin;