import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { IoMdPerson } from 'react-icons/io';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { NonceProvider } from 'react-select';

const EditProfile = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  //const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [timeZone, setTimeZone] = useState('');
  const [workingHours, setWorkingHours] = useState('');
  const [introduction, setIntroduction] = useState('');

  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  const timeZones = moment.tz.names();

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleFileUpload = async (e) => {
    console.log('log event', e);
    const file = e.target.files[0];
    if (file) {
      // Display the selected image locally
      setImage(URL.createObjectURL(file));

      // Send the image to the backend
      const formData = new FormData();
      formData.append('image', file);
      //   formData.append('file', file);
      console.log('handleImageUpload', formData);
      try {
        const response = await axios.post(
          `${apiUrl}/users/upload-profile-image`,
          formData,
        );

        // Update image state with the new URL from server response
        console.log('response.data', response.data);
        if (response.data.imageUrl) {
          setImage(response.data.imageUrl);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      if (!timeZone || !firstName) {
        alert('Please fill in all the fields.');
        return;
      }

      const name = firstName; // Combine first and last name
      const lastname = lastName;
      const profileImageUrl = image;
      const confirmedStatus = true;
console.log('name:: ',name)
console.log('lastname:: ', lastname)

      const response = await axios.put(
        `${apiUrl}/users/${userId}/update-user-details`,
        {
          name, // Combine first and last name
          lastname,
          image,
          confirmedStatus,
          //  profileImageUrl,
          timeZone,
          workingHours,
          introduction,
        },
        {
          headers: {
            'x-auth-token': token, // Pass the token in the header
          },
        },
      );
      console.log(response.data);
     navigate('/dashboard');
      // Handle success, update state, etc.
    } catch (error) {
      console.error('Error updating user details:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/users/${userId}/user-details`,
          {
            headers: {
              'x-auth-token': token, // Pass the auth token in the headers
            },
          },
        );
        console.log('response-data- ', response);
        // Assuming response.data contains firstName, lastName, profileImage, and email
        setFirstName(response.data.name || '');
        setLastName(response.data.lastname || '');
        setImage(response.data.profileImageUrl || '');
        setEmail(response.data.email || '');
        setWorkingHours(response.data.preferredTime || '');
        setIntroduction(response.data.profileDescription || '');

        //setProfileImageUrl(imageUrl);

        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (timeZones.includes(userTimeZone)) {
          setTimeZone(userTimeZone);
        }

        // setEmail(response.data.email || ''); // Uncomment if you want to set the email
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    // const fetchTimeZones = async () => {
    //   try {
    //     const response = await axios.get(
    //       'http://worldtimeapi.org/api/timezone',
    //     );
    //     setTimeZones(response.data);
    //   } catch (error) {
    //     console.error('Error fetching time zones:', error);
    //   }
    // };
    if (userId && token) {
      fetchUserDetails();
      // fetchTimeZones();
    }
  }, [userId, token]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '20px',
        height: '100vh',
        backgroundColor: '#f9f9f9',
      }}
    >
      <Box
        sx={{
          width: '600px',
          backgroundColor: '#fff',
          borderRadius: 2,
          boxShadow: 3,

          textAlign: 'center',
        }}
      >
        {/* Profile Image */}
        <Box
          sx={{
            mb: 3,
            pt: 3,
            backgroundColor: 'rgb(247 251 238)',
            height: '230px',
          }}
        >
          <Box
            sx={{
              width: 120,
              height: 120,
              margin: '0 auto',
              borderRadius: '50%',
              overflow: 'hidden',
              border: '4px solid #2a9d29',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {image ? (
              <Avatar
                src={image}
                alt="Profile"
                sx={{ width: '100%', height: '100%' }}
              />
            ) : (
              <IoMdPerson size={60} color="#ccc" />
            )}
          </Box>
          {/* Image Upload Button */}
          <Button
            variant="contained"
            component="label"
            sx={{
              mt: 2,
              fontSize: '12px',
              color: '#2a9d29',
              backgroundColor: 'transparent',
              textTransform: 'none',
              fontWeight: 'normal',
              display:'block',
              padding: 0, // Remove default padding
              minWidth: 0, // Remove minimum width
              boxShadow: 'none', // Remove box shadow
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: 'transparent', // No hover background
                color: 'green', // Retain the green text color on hover
              },
              '&:focus': {
                backgroundColor: 'transparent', // No focus background
              },
            }}
          >
            Change profile picture
            <input
              type="file"
              id="profileImage"
              hidden
              onChange={handleFileUpload}
            />
          </Button>

          <TextField
    value={firstName}
    onChange={(e) => setFirstName(e.target.value)}
    placeholder="Enter First Name"
    sx={{
        mt: 2,
        color: '#2a9d29',
        backgroundColor: 'white',
        '& .MuiInputBase-input': {
            color: '#2a9d29',
            fontSize: 'inherit',
            padding: 0,
            margin: 0,
            border: 'none',
            outline: 'none',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none', // Remove border
            },
            '&:hover fieldset': {
                border: 'none', // Remove border on hover
            },
            '&.Mui-focused fieldset': {
                border: 'none', // Remove border on focus
            },
        },
        '& .MuiInputBase-input::placeholder': {
            color: '#a0a0a0', // Set placeholder color
            opacity: 1, // Ensure placeholder is fully opaque
        },
    }}
    InputProps={{
        disableUnderline: true, // Remove underline
    }}
/>
<TextField
    value={lastName}
    onChange={(e) => setLastName(e.target.value)}
    placeholder="Enter Last Name"
    sx={{
        mt: 2,
        color: '#2a9d29',
        backgroundColor: 'white',
        '& .MuiInputBase-input': {
            color: '#2a9d29',
            fontSize: 'inherit',
            padding: 0,
            margin: 0,
            border: 'none',
            outline: 'none',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none', // Remove border
            },
            '&:hover fieldset': {
                border: 'none', // Remove border on hover
            },
            '&.Mui-focused fieldset': {
                border: 'none', // Remove border on focus
            },
        },
        '& .MuiInputBase-input::placeholder': {
            color: '#a0a0a0', // Set placeholder color
            opacity: 1, // Ensure placeholder is fully opaque
        },
    }}
    InputProps={{
        disableUnderline: true, // Remove underline
    }}
/>
         
        </Box>

        {/* Form Fields */}
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            paddingLeft: 2, // Left padding
            paddingRight: 2, // Right padding
          }}
        >
          {/* <Typography
            variant="h6"
            align="left"
            gutterBottom
            sx={{
              color: 'blue', // Custom color
              fontWeight: 'bold', // Custom font weight
              textTransform: 'uppercase', // Example: Uppercase text
            }}
          >
            Edit Profile
          </Typography> */}

          <FormControl fullWidth required>
            <InputLabel>Time Zone</InputLabel>
            <Select
              value={timeZone}
              onChange={(e) => setTimeZone(e.target.value)}
              label="Time Zone"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300, // Set a maximum height for the dropdown
                    overflow: 'auto',
                    width: '20%', // Enable scroll if the content overflows
                  },
                },
              }}
              sx={{
                textAlign: 'left', // Aligns text in the dropdown
                '.MuiSelect-select': {
                  textAlign: 'left', // Aligns text in the input field
                },
              }}
            >
              <MenuItem value="">Select Time Zone</MenuItem>
              {timeZones.map((zone, index) => (
                <MenuItem key={index} value={zone}>
                  {zone} ({moment.tz(zone).format('UTCZ')})
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Preferred Working Hours"
            value={workingHours}
            onChange={(e) => setWorkingHours(e.target.value)}
            margin="normal"
            variant="outlined"
          />

          <TextField
            fullWidth
            label="Introduction"
            value={introduction}
            onChange={(e) => setIntroduction(e.target.value)}
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
          />
        </Box>

        {/* Save Button */}
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          sx={{ mt: 3, mb: 3 }}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default EditProfile;
